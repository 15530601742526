<template>
  <div class="main-content top">
    <div class="profile-main">
      <div class="profile-head" @click="logoutSite">
        <img :src="require('@/assets/img/profilebg.svg')" alt="" />
      </div>
      <div class="profile-img-wrapper">
        <div class="profile-img">
          <img :src="avatar" alt="" />
        </div>
        <div class="user-name">{{ name }}</div>
      </div>
    </div>
    <div class="profile-menu-list">
      <router-link class="list-item info" :to="{ name: 'OrderList' }">
        從網站下訂查詢
      </router-link>
      <router-link
        class="list-item info"
        :to="{ name: 'MemberDefaultSetting' }"
      >
        開團資訊
      </router-link>
      <router-link
        class="list-item cal"
        :to="{ name: 'MemberCalendar', params: { time: nowMonth } }"
      >
        個人行事曆
      </router-link>
      <a class="list-item teach" @click.prevent="tutorials">教學使用</a>
      <router-link class="list-item feedback" :to="{ name: 'Feedback' }">
        意見回饋
      </router-link>
      <router-link class="list-item others" :to="{ name: 'OtherSetting' }">
        其他設定
      </router-link>
      <router-link
        class="list-item privacy"
        :to="{ name: 'PrivacyTermSetting' }"
      >
        隱私權政策
      </router-link>
    </div>
    <MessageAlert
      :show="showMessageModal"
      @close-modal="confirmMsg"
      :msgTitle="msgTitle"
      :msgContent="msgContent"
    ></MessageAlert>
  </div>
</template>

<script>
const moment = require('moment');
import { mapActions, mapGetters } from 'vuex';
import { isEmpty } from 'lodash';
import MessageAlert from '@/components/Modals/ModalMessageAlert';

export default {
  components: {
    MessageAlert
  },
  data() {
    return {
      nowMonth: moment().format('YYYY-MM'),
      //for message
      showMessageModal: false,
      msgTitle: null,
      msgContent: null
    };
  },
  watch: {
    name: {
      immediate: true,
      handler(val) {
        if (isEmpty(val)) {
          this.getInfo();
        }
      }
    }
  },
  computed: {
    ...mapGetters(['name', 'avatar'])
  },
  methods: {
    ...mapActions('user', ['getInfo', 'logout']),
    tutorials() {
      this.showNotYetMsg();
    },
    confirmMsg() {
      this.reset();
    },
    reset() {
      this.showMessageModal = false;
      this.msgTitle = null;
      this.msgContent = null;
    },
    showNotYetMsg() {
      this.showMessageModal = true;
      this.msgTitle = '提示';
      this.msgContent = '即將推出，敬請期待';
    },
    async logoutSite() {
      await this.logout();
      this.$router.push('login');
    }
  }
};
</script>
